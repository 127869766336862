<template>
	<div>
		<div>
			<div class="d-flex justify-content-between align-items-center">
				<div class="pr-3">
					<h2 class="font-size-18 font-weight-bold mb-1 text-dark">Meta semestral</h2>
					<p class="font-size-15 mb-3">Primer semestre 2023 | 01 de Enero de 2023 al 30 de Junio de 2023</p>
				</div>
				<div class="text-primary font-weight-bold font-size-24">
					$6K
					<a-button class="btn btn-success" size="small" icon="reload" @click="initModule" />
				</div>
			</div>
			<div class="mb-3" v-if="dashboardReport.totalInbound > 0">
				<vue-chartist class="height-200" :data="dashboardReport.groupData" :options="options" />
				<a-progress type="line" :showInfo="false" :strokeWidth="30" status="active" :strokeColor="{
					'0%': '#f8d7da',
					'50%': '#fff3cd',
					'100%': '#d1e7dd',
				}" :percent="dashboardReport.percent" />
			</div>
			<div class="d-flex text-gray-5 justify-content-between font-size-14">
				<span class="text-uppercase">{{ dashboardReport.percent }}%</span>
			</div>
		</div>
		<hr class="hrText" data-content="♦" />
		<a-tabs @change="onChangeTab" :activeKey="activeTab">
			<a-tab-pane tab="General" key="general" force-render>
				<div class="row">
					<div class="col-md-3 text-center">
						<div class="card bg-success border-0" style="height: 118px">
							<div class="card-body">
								<div class="text-white font-weight-bold">
									<div class="font-size-21">Ingresos semestral</div>
									<div class="font-size-18">{{ numeral(dashboardReport.totalInbound).format('$0,0.00') }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-3 text-center">
						<div class="card bg-danger border-0" style="height: 118px">
							<div class="card-body">
								<div class="text-white font-weight-bold">
									<div class="font-size-21">Egresos semestral</div>
									<div class="font-size-18">{{ numeral(dashboardReport.totalOutbound).format('$0,0.00') }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-3 text-center">
						<div class="card bg-warning border-0" style="height: 118px">
							<div class="card-body">
								<div class="text-white font-weight-bold">
									<div class="font-size-21">Cuentas por Cobrar</div>
									<div class="font-size-18">{{ numeral(dashboardReport.totalReceivable).format('$0,0.00') }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-3 text-center">
						<div class="card bg-info border-0">
							<div class="card-body pointer" @click="onBudgetAllocation">
								<div class="text-white font-weight-bold">
									<div class="font-size-21">Disponible Total</div>
									<div class="font-size-18">{{ numeral(dashboardReport.totalAvailable + dashboardReport.availableZem).format('$0,0.00') }}</div>
									<div class="font-size-14">
										{{ numeral(dashboardReport.totalAvailable).format('$0,0.00') }} - RESGUARDO
										<br>
										{{ numeral(dashboardReport.availableZem).format('$0,0.00') }} - ASIGNADO
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</a-tab-pane>
			<a-tab-pane tab="Ingresos" key="available" force-render>
				<div class="row">
					<div class="col-md-12">
						<a-radio-group button-style="solid" :default-value="monthSelected" @change="onChangeMonth">
							<a-radio-button v-for="(month, index) in months" :key="index" :value="month.id" :disabled="month.id > actualMonth"> {{ month.name }} </a-radio-button>
						</a-radio-group>
					</div>
				</div>
				<div class="row pt20">
					<div class="col-md-6 text-center">
						<div class="fs40">{{ numeral(dashboardReport.granTotal).format('$0,0.00') }}</div>
						Ingreso total mensual
					</div>
					<div class="col-md-6 text-center">
						<div class="fs40">{{ numeral(dashboardReport.totalReceivable).format('$0,0.00') }}</div>
						Cuentas por Cobrar
					</div>
				</div>
				<div class="row pt20" v-if="false">
					<div class="col-md-3 text-center">
						<div class="fs25">{{ numeral(getIVA).format('$0,0.00') }}</div>
						IVA cobrado
					</div>
					<div class="col-md-3 text-center">
						<div class="fs25">{{ numeral(getSubtotalISR).format('$0,0.00') }}</div>
						Ingreso para cálculo de ISR
					</div>
					<div class="col-md-3 text-center">
						<div class="fs25">{{ numeral(getISR).format('$0,0.00') }}</div>
						ISR por pagar
					</div>
					<div class="col-md-3 text-center">
						<div class="fs25">{{ numeral(monthlyTotal).format('$0,0.00') }}</div>
						Ingreso total - ISR - IVA
					</div>
				</div>
				<div class="row pt20" v-if="false">
					<div class="col-md-12">
						<table class="table table-bordered table-condensed tablePadding25">
							<tbody>
								<tr class="bg-info color text-white">
									<td width="40%"></td>
									<td width="20%" class="text-center"><b>PISO DE VENTA</b></td>
									<td width="20%" class="text-center"><b>REFACCIONES</b></td>
									<td width="20%" class="text-center"><b>SERVICIO</b></td>
								</tr>
								<tr>
									<td>Ventas</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.totals.boutique).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.totals.spareParts).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.totals.service).format('$0,0.00') }}</b>
									</td>
								</tr>
								<tr>
									<td>Costos</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.totalCosts.boutique).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.totalCosts.spareParts).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<!-- <b>{{ numeral(dashboardReport.expenses.direct_payroll.service).format('$0,0.00') }}</b> -->
									</td>
								</tr>
								<tr class="bg-success text-white">
									<td>Rentabilidad</td>
									<td class="text-right">
										<b>{{ numeral(getProfit('boutique')).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(getProfit('spareParts')).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<!-- <b>{{ numeral(getProfit('service')).format('$0,0.00') }}</b> -->
									</td>
								</tr>
								<!-- <tr>
									<td>Nomina Directa</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.expenses.direct_payroll.boutique).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.expenses.direct_payroll.spareParts).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>-</b>
									</td>
								</tr>
								<tr>
									<td>Nomina Indirecta</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.expenses.indirect_payroll).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.expenses.indirect_payroll).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.expenses.indirect_payroll).format('$0,0.00') }}</b>
									</td>
								</tr>
								<tr>
									<td>Gastos Fijos</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.expenses.fixed).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.expenses.fixed).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.expenses.fixed).format('$0,0.00') }}</b>
									</td>
								</tr>
								<tr>
									<td>Gastos Variables</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.expenses.general.boutique).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.expenses.general.spareParts).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.expenses.general.service).format('$0,0.00') }}</b>
									</td>
								</tr>
								<tr>
									<td>Gastos Generales</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.expenses.general.general).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.expenses.general.general).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.expenses.general.general).format('$0,0.00') }}</b>
									</td>
								</tr>
								<tr>
									<td>IVA Trasladado</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.monthlyTaxes.boutique.iva).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.monthlyTaxes.spareParts.iva).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.monthlyTaxes.service.iva).format('$0,0.00') }}</b>
									</td>
								</tr>-->
								<!-- <tr>
									<td>IVA Acreditado</td>
									<td class="text-right">
										<b>{{ numeral(getCreditedTax('boutique')).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(getCreditedTax('spareParts')).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(getCreditedTax('service')).format('$0,0.00') }}</b>
									</td>
								</tr> -->
								<!-- <tr>
									<td>IVA por Pagar</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.monthlyTaxes.boutique.iva - getCreditedTax('boutique')).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.monthlyTaxes.spareParts.iva - getCreditedTax('spareParts')).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.monthlyTaxes.service.iva - getCreditedTax('service')).format('$0,0.00') }}</b>
									</td>
								</tr> -->
								<!-- <tr>
									<td>ISR</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.monthlyTaxes.boutique.isr).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.monthlyTaxes.spareParts.isr).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(dashboardReport.monthlyTaxes.service.isr).format('$0,0.00') }}</b>
									</td>
								</tr> -->
								<!-- <tr class="bg-success text-white">
									<td>GANANCIA</td>
									<td class="text-right">
										<b>{{ numeral(calculateProfit('boutique')).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(calculateProfit('spareParts')).format('$0,0.00') }}</b>
									</td>
									<td class="text-right">
										<b>{{ numeral(calculateProfit('service')).format('$0,0.00') }}</b>
									</td>
								</tr> -->
							</tbody>
						</table>
					</div>
				</div>
			</a-tab-pane>
		</a-tabs>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueChartist from 'v-chartist'
import ChartistTooltip from 'chartist-plugin-tooltips-updated'
import numeral from 'numeral'
import Swal from 'sweetalert2'
import moment from 'moment'

export default {
	name: 'directionDashboard',
	components: {
		'vue-chartist': VueChartist,
	},
	computed: {
		...mapGetters('direction', ['dashboardReport']),
		getIVA() {
			return (this.dashboardReport.granTotal / 1.16) * 0.16
		},
		getSubtotalISR() {
			return this.dashboardReport.granTotal - this.getIVA
		},
		getISR() {
			return this.getSubtotalISR * 0.1131 * 0.3
		},
		monthlyTotal() {
			return this.getSubtotalISR - this.getISR
		},
	},
	data() {
		const options = {
			chartPadding: {
				right: 50,
				left: 20,
				top: 0,
				bottom: 0,
			},
			fullWidth: true,
			showPoint: true,
			lineSmooth: true,
			axisY: {
				showGrid: false,
				showLabel: false,
				offset: 0,
				labelInterpolationFnc: (value) => {
					return '$' + value
				},
			},
			axisX: {
				showGrid: true,
				showLabel: true,
				offset: 25,
			},
			showArea: false,
			plugins: [
				ChartistTooltip({
					anchorToPoint: false,
					appendToBody: true,
					seriesName: false,
					transformTooltipTextFnc: (value) => {
						return ''
					},
				}),
			],
		}

		return {
			activeTab: 'general', // general / available
			options,
			months: [
				{
					name: 'ENERO',
					id: '01',
				},
				{
					name: 'FEBRERO',
					id: '02',
				},
				{
					name: 'MARZO',
					id: '03',
				},
				{
					name: 'ABRIL',
					id: '04',
				},
				{
					name: 'MAYO',
					id: '05',
				},
				{
					name: 'JUNIO',
					id: '06',
				},
				{
					name: 'JULIO',
					id: '07',
				},
				{
					name: 'AGOSTO',
					id: '08',
				},
				{
					name: 'SEPTIEMBRE',
					id: '09',
				},
				{
					name: 'OCTUBRE',
					id: '10',
				},
				{
					name: 'NOVIEMBRE',
					id: '11',
				},
				{
					name: 'DICIEMBRE',
					id: '12',
				},
			],
			monthSelected: moment().format('MM').toString(),
			actualMonth: moment().format('MM').toString(),
		}
	},
	methods: {
		numeral,
		initModule() {
			this.$store.dispatch('direction/GET_DASHBOARD_REPORT')
		},
		onChangeTab(key) {
			this.activeTab = key
		},
		onBudgetAllocation() {
			Swal.fire({
				title: 'Ingresa monto a asignar',
				input: 'text',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'Cancelar',
				inputAttributes: {
					autocorrect: 'off',
					autocomplete: 'off',
				},
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (!isNaN(value)) {
							if (!value || Number(value) < 1) {
								resolve('Es necesario ingresar un monto válido')
							}
						} else {
							resolve('Es necesario ingresar un monto válido')
						}
						resolve()
					})
				},
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('warehouseCashflow/BUDGET_ALLOCATION', { amount: numeral(result.value).value() })
				}
			})
		},
		onChangeMonth(e) {
			this.$store.dispatch('direction/GET_DASHBOARD_REPORT', {
				month: e.target.value,
			})
		},
		getProfit(area) {
			//
			if (area == 'service') {
				return this.dashboardReport.totals[area] - this.dashboardReport.expenses.direct_payroll[area]
			} else {
				return this.dashboardReport.totals[area] - this.dashboardReport.totalCosts[area]
			}
		},
		getCreditedTax(area) {
			let totalIn = this.dashboardReport.expenses.fixed + this.dashboardReport.expenses.general[area] + this.dashboardReport.expenses.general.general
			return totalIn - totalIn / 1.16
		},
		calculateProfit(area) {
			return (
				this.getProfit(area) -
				(this.dashboardReport.expenses.direct_payroll[area] +
					this.dashboardReport.expenses.indirect_payroll +
					this.dashboardReport.expenses.fixed +
					this.dashboardReport.expenses.general.general +
					this.getCreditedTax(area) +
					this.dashboardReport.monthlyTaxes[area].isr)
			)
		},
	},
}
</script>

<style >
.ct-series-a .ct-line,
.ct-series-a .ct-point {
	stroke: #41b883;
}

.ct-series-b .ct-line,
.ct-series-b .ct-point {
	stroke: #f5222e;
}
</style>